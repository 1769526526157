import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BlocksComponent } from './components/blocks/blocks.component';
import { CarouselBlockComponent } from './components/blocks/carousel-block/carousel-block.component';
import { ColumnsBlockComponent } from './components/blocks/columns-block/columns-block.component';
import { CoverBlockComponent } from './components/blocks/cover-block/cover-block.component';
import { FocusBlockComponent } from './components/blocks/focus-block/focus-block.component';
import { FormBlockComponent } from './components/blocks/form-block/form-block.component';
import { GalleryBlockComponent } from './components/blocks/gallery-block/gallery-block.component';
import { IframeBlockComponent } from './components/blocks/iframe-block/iframe-block.component';
import { ImageBlockComponent } from './components/blocks/image-block/image-block.component';
import { ImagesBlockComponent } from './components/blocks/images-block/images-block.component';
import { LinksBlockComponent } from './components/blocks/links-block/links-block.component';
import { ProductsBlockComponent } from './components/blocks/products-block/products-block.component';
import { QuoteBlockComponent } from './components/blocks/quote-block/quote-block.component';
import { ShowcaseBlockComponent } from './components/blocks/showcase-block/showcase-block.component';
import { SliderBlockComponent } from './components/blocks/slider-block/slider-block.component';
import { SlideshowBlockComponent } from './components/blocks/slideshow-block/slideshow-block.component';
import { TaxonSeoBlockComponent } from './components/blocks/taxon-seo-block/taxon-seo-block.component';
import { TeamsBlockComponent } from './components/blocks/teams-block/teams-block.component';
import { TeamsGridBlockComponent } from './components/blocks/teams-grid-block/teams-grid-block.component';
import { TextBlockComponent } from './components/blocks/text-block/text-block.component';
import { TextImageBlockComponent } from './components/blocks/text-image-block/text-image-block.component';
import { AthleteCardComponent } from './components/cards/athlete-card/athlete-card.component';
import { CartItemCardComponent } from './components/cards/cart-item-card/cart-item-card.component';
import { FaqCardComponent } from './components/cards/faq-card/faq-card.component';
import { NewsCardComponent } from './components/cards/news-card/news-card.component';
import { OrderItemCardComponent } from './components/cards/order-item-card/order-item-card.component';
import { SearchResultCardComponent } from './components/cards/search-result-card/search-result-card.component';
import { SlideCardComponent } from './components/cards/slide-card/slide-card.component';
import { SuggestionCardComponent } from './components/cards/suggestion-card/suggestion-card.component';
import { TechnoCardComponent } from './components/cards/techno-card/techno-card.component';
import { VariantCardComponent } from './components/cards/variant-card/variant-card.component';
import { VariantSlideCardComponent } from './components/cards/variant-slide-card/variant-slide-card.component';
import { AthleteNetworksComponent } from './components/content/athlete-networks/athlete-networks.component';
import { AthleteSliderComponent } from './components/content/athlete-slider/athlete-slider.component';
import { FaqSliderComponent } from './components/content/faq-slider/faq-slider.component';
import { OpeningHoursComponent } from './components/content/opening-hours/opening-hours.component';
import { PlaceListItemComponent } from './components/content/place-list-item/place-list-item.component';
import { PriceComponent } from './components/content/price/price.component';
import { PrivacyMentionsComponent } from './components/content/privacy-mentions/privacy-mentions.component';
import { TaxonPushComponent } from './components/content/taxon-push/taxon-push.component';
import { VariantSliderComponent } from './components/content/variant-slider/variant-slider.component';
import { VariantsSuggestionsComponent } from './components/content/variants-suggestions/variants-suggestions.component';
import { AddressValidationDialogComponent } from './components/dialog/address-validation-dialog/address-validation-dialog.component';
import { CartCtaDialogComponent } from './components/dialog/cart-cta-dialog/cart-cta-dialog.component';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import { GuideDialogComponent } from './components/dialog/guide-dialog/guide-dialog.component';
import { PlaceDetailDialogComponent } from './components/dialog/place-detail-dialog/place-detail-dialog.component';
import { StockAlertDialogComponent } from './components/dialog/stock-alert-dialog/stock-alert-dialog.component';
import { AddressFormComponent } from './components/form/address-form/address-form.component';
import { CheckboxComponent } from './components/form/checkbox/checkbox.component';
import { ColorSelectComponent } from './components/form/color-select/color-select.component';
import { FileInputComponent } from './components/form/file-input/file-input.component';
import { NumberStepperComponent } from './components/form/number-stepper/number-stepper.component';
import { PhoneInputComponent } from './components/form/phone-input/phone-input.component';
import { RadioGroupComponent } from './components/form/radio-group/radio-group.component';
import { SearchInputComponent } from './components/form/search-input/search-input.component';
import { SelectComponent } from './components/form/select/select.component';
import { TextInputComponent } from './components/form/text-input/text-input.component';
import { VariantOptionComponent } from './components/form/variant-select/variant-option/variant-option.component';
import { VariantSelectComponent } from './components/form/variant-select/variant-select.component';
import { VariantToggleComponent } from './components/form/variant-toggle/variant-toggle.component';
import { PanelLayoutComponent } from './components/layouts/panel-layout/panel-layout.component';
import { PopinLayoutComponent } from './components/layouts/popin-layout/popin-layout.component';
import { UserLayoutComponent } from './components/layouts/user-layout/user-layout.component';
import { ButtonComponent } from './components/ui/button/button.component';
import { CartButtonComponent } from './components/ui/cart-button/cart-button.component';
import { ColorDotComponent } from './components/ui/color-dot/color-dot.component';
import { EnableCookiesComponent } from './components/ui/enable-cookies/enable-cookies.component';
import { HeroComponent } from './components/ui/hero/hero.component';
import { IconButtonComponent } from './components/ui/icon-button/icon-button.component';
import { IconComponent } from './components/ui/icon/icon.component';
import { ImageStackerComponent } from './components/ui/image-stacker/image-stacker.component';
import { ImageComponent } from './components/ui/image/image.component';
import { LinksComponent } from './components/ui/links/links.component';
import { LoaderComponent } from './components/ui/loader/loader.component';
import { LocaleDropdownComponent } from './components/ui/locale-dropdown/locale-dropdown.component';
import { MediaComponent } from './components/ui/media/media.component';
import { MenuButtonComponent } from './components/ui/menu-button/menu-button.component';
import { MenuLinkComponent } from './components/ui/menu-link/menu-link.component';
import { OutlineButtonComponent } from './components/ui/outline-button/outline-button.component';
import { PaginationComponent } from './components/ui/pagination/pagination.component';
import { PullBoxComponent } from './components/ui/pull-box/pull-box.component';
import { SnackComponent } from './components/ui/snackbar/snack/snack.component';
import { SnackbarComponent } from './components/ui/snackbar/snackbar.component';
import { ToggleableComponent } from './components/ui/toggleable/toggleable.component';
import { WysiwygComponent } from './components/ui/wysiwyg/wysiwyg.component';
import { ClickAwayDirective } from './directives/click-away.directive';
import { DisableOnMoveDirective } from './directives/disable-on-move.directive';
import { MarqueeDirective } from './directives/marquee.directive';
import { RippleDirective } from './directives/ripple.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { SlideToggleDirective } from './directives/slide-toggle.directive';
import { StickToHeaderDirective } from './directives/stick-to-header.directive';
import { ActivityPipe } from './pipes/activity.pipe';
import { BreadcrumbPipe } from './pipes/breadcrumb.pipe';
import { CountryPipe } from './pipes/country.pipe';
import { ErpStatusLabelPipe } from './pipes/erp-status-label.pipe';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { ForNumberPipe } from './pipes/for-number.pipe';
import { FragmentPipe } from './pipes/fragment.pipe';
import { InArrayPipe } from './pipes/in-array.pipe';
import { InternalLinkPipe } from './pipes/internal-link.pipe';
import { JoinArrayPipe } from './pipes/join-array.pipe';
import { MapLinkPipe } from './pipes/map-link.pipe';
import { MenuPipe } from './pipes/menu.pipe';
import { OpeningPeriodLabelPipe } from './pipes/opening-period-label.pipe';
import { OptionToColorPipe } from './pipes/option-to-color.pipe';
import { OptionPipe } from './pipes/option.pipe';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { PracticePipe } from './pipes/practice.pipe';
import { PricePipe } from './pipes/price.pipe';
import { PromoPipe } from './pipes/promo.pipe';
import { RoutePipe } from './pipes/route.pipe';
import { SubtotalPipe } from './pipes/subtotal.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { TrustPipe } from './pipes/trust.pipe';
import { VideoBlockComponent } from './components/blocks/video-block/video-block.component';
import { FilesBlockComponent } from './components/blocks/files-block/files-block.component';
import { TotalPipe } from './pipes/total.pipe';
import { ZoomDialogComponent } from './components/dialog/zoom-dialog/zoom-dialog.component';
import { OverlayLayoutComponent } from './components/layouts/overlay-layout/overlay-layout.component';
import { CartOptionsComponent } from './components/content/cart-options/cart-options.component';
import { AltColorsComponent } from './components/ui/alt-colors/alt-colors.component';
import { OrderItemPricePipe } from './pipes/order-item-price.pipe';
import { CartSelectComponent } from './components/content/cart-options/cart-select/cart-select.component';
import { OrderItemBundlePipe } from './pipes/order-item-bundle.pipe';
import { TaxonFiltersDialogComponent } from './components/dialog/taxon-filters-dialog/taxon-filters-dialog.component';
import { TagComponent } from './components/ui/tag/tag.component';
import { TaxonFiltersComponent } from './components/content/taxon-filters/taxon-filters.component';
import { RouteParamsPipe } from './pipes/route-params.pipe';

@NgModule({
  declarations: [
    RoutePipe,
    RouteParamsPipe,
    TranslatePipe,
    TrustPipe,
    IconComponent,
    IconButtonComponent,
    RippleDirective,
    MenuPipe,
    ButtonComponent,
    OutlineButtonComponent,
    PaginationComponent,
    TextInputComponent,
    SelectComponent,
    VariantSelectComponent,
    VariantOptionComponent,
    VariantToggleComponent,
    NumberStepperComponent,
    ClickAwayDirective,
    InArrayPipe,
    JoinArrayPipe,
    LocaleDropdownComponent,
    CountryPipe,
    ConfirmDialogComponent,
    HeroComponent,
    ColorDotComponent,
    PricePipe,
    PromoPipe,
    BlocksComponent,
    TaxonSeoBlockComponent,
    WysiwygComponent,
    LoaderComponent,
    ImageComponent,
    ProductsBlockComponent,
    CarouselBlockComponent,
    TeamsBlockComponent,
    VariantCardComponent,
    VariantSlideCardComponent,
    AthleteCardComponent,
    ImageStackerComponent,
    SlideToggleDirective,
    SliderBlockComponent,
    SlideCardComponent,
    ShowcaseBlockComponent,
    OrderStatusPipe,
    UserLayoutComponent,
    SnackbarComponent,
    ColorSelectComponent,
    ScrollToDirective,
    ToggleableComponent,
    TechnoCardComponent,
    VariantSliderComponent,
    TextBlockComponent,
    NewsCardComponent,
    TextImageBlockComponent,
    ActivityPipe,
    ColorSelectComponent,
    ScrollToDirective,
    ToggleableComponent,
    GalleryBlockComponent,
    MediaComponent,
    PopinLayoutComponent,
    PanelLayoutComponent,
    GuideDialogComponent,
    ForNumberPipe,
    CartItemCardComponent,
    StickToHeaderDirective,
    CartCtaDialogComponent,
    SuggestionCardComponent,
    PullBoxComponent,
    PracticePipe,
    BreadcrumbPipe,
    SearchInputComponent,
    FirstLetterPipe,
    MapLinkPipe,
    OpeningPeriodLabelPipe,
    LinksBlockComponent,
    LinksComponent,
    FocusBlockComponent,
    CoverBlockComponent,
    IframeBlockComponent,
    EnableCookiesComponent,
    ImagesBlockComponent,
    ColumnsBlockComponent,
    VariantsSuggestionsComponent,
    SnackComponent,
    CartButtonComponent,
    AddressFormComponent,
    PlaceListItemComponent,
    OpeningHoursComponent,
    PlaceDetailDialogComponent,
    ErpStatusLabelPipe,
    OrderItemCardComponent,
    FaqSliderComponent,
    FaqCardComponent,
    SearchResultCardComponent,
    PriceComponent,
    InternalLinkPipe,
    TeamsGridBlockComponent,
    DisableOnMoveDirective,
    AthleteNetworksComponent,
    AthleteSliderComponent,
    QuoteBlockComponent,
    ImageBlockComponent,
    SlideshowBlockComponent,
    PhoneInputComponent,
    PrivacyMentionsComponent,
    CheckboxComponent,
    FormBlockComponent,
    RadioGroupComponent,
    MenuLinkComponent,
    MenuButtonComponent,
    TaxonPushComponent,
    SubtotalPipe,
    TotalPipe,
    FileInputComponent,
    OptionPipe,
    OptionToColorPipe,
    FragmentPipe,
    StockAlertDialogComponent,
    AddressValidationDialogComponent,
    MarqueeDirective,
    VideoBlockComponent,
    FilesBlockComponent,
    ZoomDialogComponent,
    OverlayLayoutComponent,
    CartOptionsComponent,
    AltColorsComponent,
    OrderItemPricePipe,
    OrderItemBundlePipe,
    CartSelectComponent,
    TaxonFiltersDialogComponent,
    TagComponent,
    TaxonFiltersComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    HammerModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RoutePipe,
    RouteParamsPipe,
    MenuPipe,
    TranslatePipe,
    TrustPipe,
    ButtonComponent,
    OutlineButtonComponent,
    IconComponent,
    IconButtonComponent,
    PaginationComponent,
    TextInputComponent,
    SelectComponent,
    VariantSelectComponent,
    VariantToggleComponent,
    NumberStepperComponent,
    HeroComponent,
    RippleDirective,
    ClickAwayDirective,
    LocaleDropdownComponent,
    CountryPipe,
    ConfirmDialogComponent,
    InArrayPipe,
    JoinArrayPipe,
    ColorDotComponent,
    PricePipe,
    PromoPipe,
    BlocksComponent,
    WysiwygComponent,
    LoaderComponent,
    ImageComponent,
    VariantCardComponent,
    ImageStackerComponent,
    OrderStatusPipe,
    UserLayoutComponent,
    SnackbarComponent,
    ColorSelectComponent,
    ScrollToDirective,
    ToggleableComponent,
    TechnoCardComponent,
    VariantSliderComponent,
    TextBlockComponent,
    NewsCardComponent,
    TextImageBlockComponent,
    VariantSlideCardComponent,
    ProductsBlockComponent,
    ActivityPipe,
    ColorSelectComponent,
    ScrollToDirective,
    ToggleableComponent,
    CartItemCardComponent,
    StickToHeaderDirective,
    SlideToggleDirective,
    PullBoxComponent,
    PracticePipe,
    BreadcrumbPipe,
    SearchInputComponent,
    FirstLetterPipe,
    MapLinkPipe,
    OpeningPeriodLabelPipe,
    LinksComponent,
    EnableCookiesComponent,
    VariantsSuggestionsComponent,
    CartButtonComponent,
    PanelLayoutComponent,
    AddressFormComponent,
    PlaceListItemComponent,
    OpeningHoursComponent,
    PlaceDetailDialogComponent,
    ErpStatusLabelPipe,
    OrderItemCardComponent,
    FaqSliderComponent,
    SearchResultCardComponent,
    PriceComponent,
    DisableOnMoveDirective,
    AthleteNetworksComponent,
    AthleteSliderComponent,
    PhoneInputComponent,
    PrivacyMentionsComponent,
    CheckboxComponent,
    MenuLinkComponent,
    MenuButtonComponent,
    TaxonPushComponent,
    SubtotalPipe,
    TotalPipe,
    FileInputComponent,
    OptionPipe,
    OptionToColorPipe,
    MediaComponent,
    MarqueeDirective,
    ZoomDialogComponent,
    CartOptionsComponent,
    OrderItemPricePipe,
    OrderItemBundlePipe,
    TaxonFiltersComponent,
  ],
  providers: [CurrencyPipe],
})
export class SharedModule {}
